import Glide from '@glidejs/glide';
import MobileDetect from 'mobile-detect';

// bind mount/destroy events for the Glide slider
export default function hookSlider() {
    let slider = null, orientation = 'portrait';
    const device = new MobileDetect(window.navigator.userAgent);
    const mql = window.matchMedia("(orientation: portrait)");

    function isMobile() {
        return document.body.clientWidth < 576 || (!!device.mobile() && !device.tablet());
    }

    // watch orientation
    function updateOrientation(obj) {
        orientation = obj.matches ? 'portrait' : 'landscape';
    }
    updateOrientation(mql);
    if (!!mql.addEventListener) {
        mql.addEventListener("change", updateOrientation);
    } else {
        // fallback for older browsers (IE, Safari)
        mql.addListener(updateOrientation);
    }

    // init or destroy Glide
    function initSlider() {
        if (isMobile() && orientation === 'portrait') { // is mobile portrait
            if (document.body.classList.contains('desktop')) {
                document.body.classList.remove('desktop');
            }
            if (!document.body.classList.contains('mobile')) {
                slider = new Glide('#info').mount();
                document.body.classList.add('mobile');
            }
        } else {
            if (document.body.classList.contains('mobile')) {
                if (!!slider) {
                    slider.destroy();
                    slider = null;
                }
                document.body.classList.remove('mobile');
            }
            if (!document.body.classList.contains('desktop')) {
                document.body.classList.add('desktop');
            }
        }
    }

    // fix double click bug on ios https://github.com/glidejs/glide/issues/417
    document.querySelectorAll('.glide__arrow').forEach(function(glideArrow) {
        glideArrow.addEventListener('click', function() {
            slider.go(glideArrow.dataset.glideDir);
        });
    });

    initSlider();
    window.addEventListener('resize', initSlider);
}