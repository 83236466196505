
function openModal(id, overlay) {
    const modal = document.querySelector(`.modal-${id}`);
    return function () {
        overlay.classList.remove('hidden');
        modal.classList.remove('hidden');
    }
}
function closeModals(overlay) {
    const modals = document.querySelectorAll('#modals .modal');
    return function () {
        overlay.classList.add('hidden');
        for (let i = 0; i < modals.length; i++) {
            if (!modals[i].classList.contains('hidden')) {
                modals[i].classList.add('hidden');
            }
        }
    }
}
function switchModal(id) {
    const cls = 'modal-' + id;
    const modals = document.querySelectorAll('#modals .modal');
    return function () {
        for (let i = 0; i < modals.length; i++) {
            if (modals[i].classList.contains(cls)) {
                modals[i].classList.remove('hidden');
            } else if (!modals[i].classList.contains('hidden')) {
                modals[i].classList.add('hidden');
            }
        }
    }
}

export default function bindModals() {
    const overlay = document.querySelector('.overlay');
    // attach open modal events
    let slides = document.querySelectorAll('.glide__slide');
    for (let s = 0; s < slides.length; s++) {
        const id = parseInt(slides[s].getAttribute('data-id'));
        slides[s].querySelector('img').addEventListener('click', openModal(id, overlay));
    }
    // attach modal prev/next buttons
    let navBtns = document.querySelectorAll('#modals button.prev, #modals button.next');
    for (let b = 0; b < navBtns.length; b++) {
        const id = parseInt(navBtns[b].getAttribute('data-id'));
        navBtns[b].addEventListener('click', switchModal(id, overlay));
    }
    // attach close modal events
    overlay.addEventListener('click', closeModals(overlay));
    let closeBtns = document.querySelectorAll('#modals .close');
    for (let i = 0; i < closeBtns.length; i++) {
        closeBtns[i].addEventListener('click', closeModals(overlay));
    }
}